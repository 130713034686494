.NavigationItems {
  margin:0;
  list-style: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
}
.NavigationItem {
  margin: 10px 0;
  display: block;
  width: 100%;
}
.NavigationItem a {
  color: #707070;
  text-decoration: none;
  width: 100%;
  display: block;
  font-size: 18px;
  line-height: 18px; 
  padding: 8px 15px;
  text-decoration: none;
  cursor: pointer;
}

.NavigationItem a.active {
  color: #cd338a;
}

/* media query */
@media (min-width: 1200px) {
  .NavigationItems {
    flex-flow: row;
  }

  .NavigationItem {
    margin: 0;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
  }
  
  .NavigationItem a {
    height: 100%;
    padding: 34px 10px;
    border-bottom: 4px solid transparent;
    width: 100%;
    display: block;
  }
  
  .NavigationItem a.active{ 
    background-color: rgb(239, 72, 120, 0.11);
    border-bottom: 4px solid #cd338a;
    color:  #ef4878;
  }
}
