.about-text{
    color: #fff;
}
.about-text p{
    font-size: 14px;
    line-height: 22px;
    font-weight: 400px;
}
/* media query */
@media (min-width: 768px){
    .about-text p{
        font-size: 18px;
        line-height: 32px;
    }
}
@media(min-width: 1200px){
    .about-text p{
        margin-right: 80px;
    }
}