.Info li{
    list-style: none;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
}
.Info li img{
    width: 30px;
}
.Info li a{
    text-decoration: none;
}
.Info span{
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 14px;
}
/* media icons */
.Media a{
    width: 46px;
    height: 46px;
    display: inline-block;
    position: relative;
    border-radius: 50%;
    margin-right: 14px;
    transition: all 0.13s ease-in-out;    
}
.Media a:hover{
    transform: translatey(-5px);
}
.Media .icon{
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    font-size: 22px;
    color: #F1F2F2;
}
.Media a.twitter{
    background-color: #55ACEE;
} 
.Media a.facebook{
    background-color: #3B5998;
} 
.Media a.googleplus{
    background-color: #F44336;
}
.Media a.linkedin{
    background-color: #007AB9;
} 
/*media query */
@media (min-width: 768px){
    .Info li{
        margin-bottom: 32px
    }
    .Info li img{
        width: 48px;
    }
    .Info span{
        font-size: 22px;
        line-height: 36px;
        margin-left: 26px;
    }
    .Media a{
        width: 46px;
        height: 46px;
    }
    .Media .icon{
        font-size: 28px;
    }
    .Media a{
        margin-right: 18px;
    }     
} 
