#team .swiper-container{
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px; 
}
#team .swiper-slide {
    background-position: center !important;
    background-size: cover !important;
    width: 300px !important;
    height: 340px !important;
    background-color: #E1E5E8;
}
#team .swiper-slide .img-box{
    width: 100%;
    height: 260px;
    overflow: hidden;
}
#team .swiper-slide .img-box img{
    width: 100%;
}
#team .details{
    box-sizing: border-box;
    padding: 20px;
    font-size: 20px;
}
#team .details h3{
    margin: 0;
    padding: 0;
    font-size: 20px;
    text-align: center;
    line-height: 20px;
    color: #4B3494;
}
#team .details h3 span{
    font-size: 16px;
    color:#707070;
}
