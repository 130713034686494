.service{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.service img{
    width: 70%;
    transition: all 0.13s ease-in-out;
}
.service img:hover{
    transform: scale(0.8);
}
.service h4{
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    line-height: 40px;
}
.service p{
    font-size: 14px;
    line-height: 20px;
    color: #fff;
}
/* media query */
@media screen and (min-width: 768px){
    .service img{
        width: 100%;
    }
    .service h4{
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        line-height: 52px;
    }
    .service p{
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        color: #fff;
    }
}