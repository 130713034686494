  .Sidedrawer{
  position: fixed;
  left: 0;
  top: 0;
  width: 280px;
  max-width: 70%;/* so that even on narrow devices it doesn't go over devices */
  height: 100%;
  z-index: 200;/* z-index > backdrop(100) cause it should be above the backdrop */
  background-color: #fff;
  padding:20px 12px;
  transition: transform 0.3s ease-out; 
  overflow: auto; 
}
.Logo{
  cursor: pointer;
}
@media (min-width: 1200px){
  .Sidedrawer{
    display: none;
  }
} 
.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(-100%);
}

/* styling logo at sidedrawer */
.sidedrawerLogo img{
  width: 70%;
}