#products .slick-slider{
    margin: 0 80px;
    padding: 0 20px;
}
#products .slick-slide{
    outline: 0;
}
#products .card-wrapper{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#products .card-wrapper:active,
#products .card-wrapper:hover,
#products .card-wrapper:focus{
    outline: none;
    border: none;
}
#products .card{
    transform: scale(0.8);
    width: 250px;
    height: 250px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}
#products .card .card-image{
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.4s ease-in-out;
}

#products .card .card-image img{
    width: 100%;
    height: 100%;
    object-fit: cover
}

#products .card .card-overlay{
    position: absolute;
    padding: 20px;
    width: 100%;
    height: 100%;
    bottom: 0 ;
    z-index: 1;
    opacity: 0;
    transition: all 0.13s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}
#products .card:hover .card-overlay{
    opacity: 1;
}
#products .card .name{
    position: absolute;
    height: 15%;
    width: 100%;
    background: rgb(246, 196, 95, 0.89);
    bottom: 0;
    left: 0;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    transition: 0.4s
}
#products .card:hover .name{
    height: 100%;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

}
#products .card h3{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
}
#products .card:hover h3{
    opacity: 0
}

#products .card .card-overlay .ProductBtn{
    display: inline-block;
    background-color: #000;
    border: 1px solid #707070;
    border-radius: 11px;
    outline: 0;
    color: #fff;
    padding: 14px 63px;
    font-size: 15px;
    text-decoration: none;
}
#products .card .card-overlay a:hover {
    text-decoration: none;
    color: #fff;
}
#products .card .card-overlay .web, 
#products .card .card-overlay .app{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#products .card .card-overlay .AppBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 8px;
    min-width: 144px;
}
#products .card .card-overlay .AppBtn p{
    font-weight: 500;
    font-size: 15px;
}


/* change the shape of arrows */
#products .slick-prev, .slick-next{
    background-color: transparent !important;
}
#products .slick-prev:hover, .slick-prev:focus 
#products .slick-next:hover, .slick-next:focus{
    color: #fff;
    outline: none !important;
    background-color: transparent !important;
}
#products .slick-prev:before, 
#products .slick-next::before{
    color: #000 !important;
}
#products .slick-prev:before{
    content: "" !important;
    background:url('../../assets/images/left-arrow.png');
    width: 24.94px;
    height: 40.44px;
    display: block;
    transform: scale(0.8);
    z-index: 2;
}
#products .slick-next::before{
    content: "" !important;
    background:url('../../assets/images/right-arrow.png');
    width: 24.94px;
    height: 40.44px;
    display: block;
    transform: scale(0.8);
    z-index: 5;
}
#products .slick-next{
    right: -60px !important;
}
#products .slick-prev{
    left: -60px !important;
}

/* media query */
@media screen and (min-width: 400px){
    #products .card{
        transform: scale(1);
    }
}
@media screen and (max-width: 500px){
    #products .slick-slider{
        margin: 0 10px;
        padding: 0 10px;
    }
    #products .slick-next{
        right: -10px !important;
    }
    #products .slick-prev{
        left: -10px !important;
    }
}
@media screen and (min-width: 768px){
    #products .slick-slider{
        padding: 0 20px;
    }

    #products .slick-slide{
        padding: 0 20px;
    }
}
@media screen and (min-width: 1300px){
    #products .slick-slider{
        padding: 0 80px;
    }
    #products .slick-slide{
        padding: 0 40px;
    }
}
@media screen and (min-width: 1700px){
    #products .slick-slide{
        padding: 0 20px;
    }
}