.error-boundary{
    height: 100vh;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #4B3494;
    font-size: 18px;
    text-align: center;
}

.error-boundary img{
    width: 70%;
}
@media (min-width: 768px){
    .error-boundary{
        font-size: 28px;
    }
    .error-boundary img{
        width: 100%;
    }
}