.wifi-symbol {
    display: none;
    position: absolute;
    left: 52%;
    top: -10px;
}
.wifi-symbol [foo], .wifi-symbol {
    display: block;
    position: absolute;
    display: inline-block;
    width: 150px;
    height: 150px;
    margin-top: -187.5px;
    transform: rotate(-45deg) translate(-100px) scale(0.3);
}
.wifi-symbol .wifi-circle {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;
    font-size: 21.4285714286px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-color: #9265f8;
    border-style: solid;
    border-width: 1em 1em 0 0;
    border-radius: 0 100% 0 0;
    opacity: 0;
    animation: wifianimation 3s infinite;
}
.wifi-symbol .wifi-circle.first {
    animation-delay: 800ms;
}
.wifi-symbol .wifi-circle.second {
    width: 5em;
    height: 5em;
    animation-delay: 400ms;
}
.wifi-symbol .wifi-circle.third {
    width: 3em;
    height: 3em;
}
.wifi-symbol .wifi-circle.fourth {
    width: 1em;
    height: 1em;
    opacity: 1;
    background-color: #9265f8;
    animation: none;
}
@keyframes wifianimation {
    0% {
        opacity: 0.4;
    }
    5% {
        opactiy: 1;
    }
    6% {
        opactiy: 0.1;
    }
    100% {
        opactiy: 0.1;
    }
}

/* media query */
@media (min-width: 768px){
    .wifi-symbol [foo], .wifi-symbol {
        transform: rotate(-45deg) translate(-100px) scale(0.5);
    }
}