form {
    width: 100%;
}
.form-header {
    color: #fff;
    font-size: 35px;
    font-weight: bold;
    line-height: 34px;
    margin-bottom: 16px;
}

form .field-container{
    margin-bottom: 14px;
}

form input,
form textarea{
    width: 100%;
    border-radius: 4px;
    outline: none;
    border: none;
    background-color: #8373b5;
    height: 60px;
    padding: 0 20px;
    font-size: 16px;
    line-height: 28px;
    font-weight: bold;
    color: #fff;
    border: 2px solid transparent;
    /*h-offset v-offset blur spread color*/
    box-shadow: 2px 0 4px rgb(0, 0, 0, 0.16);
}

form textarea{
    height: 155px;
    resize: vertical; /* remove horizontal resize */
}

form input::placeholder,
form textarea::placeholder {
    font-size: 16px;
    line-height: 28px;
    font-weight: bold;
    color: rgb(255, 255, 255, 0.27);
}

form input.error,
form textarea.error {
    border: 2px solid #cd338a;
}

.errorMessage,
.invalid-form,
.valid-form{
    margin-top: 0.5rem;
    font-size: 14px;
    font-weight: bold;
}

.errorMessage,
.invalid-form {
    color: #cd338a;
}
.valid-form{
    color: #F6C45F;
}
form button {
    width: 100%;
    border-radius: 4px;
    font-size: 20px;
    font-weight: bold;
    line-height: 34px;
    border: none;
    outline: none;
    color: #4B3494;
    background-color: #F6C45F;
    height: 60px;
    width: 100%;
    margin-top: 20px;
    cursor: pointer;
}
form button:hover{
    background-color: #f7cf7f;
}
/*mmedia query */
@media(min-width: 768px){
    form .form-header {
        font-size: 50px;
        line-height: 53px;
    }
    form button{
        width: 26%;
    }
}
@media(min-width: 992px){
    form .input-margin{
        margin-right: 1rem;
    }
}