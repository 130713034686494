*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
@font-face {
    /* for english */
    font-family: "Alegreya";
    src: url("./assets/fonts/Alegreya-Regular.ttf");
    /* for arabic */
    font-family: "Cairo";
    src: url("./assets/fonts/Cairo-Regular.ttf");
}
body{
    font-family: 'Alegreya', serif;
}
/* global */
.section-dimension{/* dimensions = top val of title*/
	margin-top: -87.5px;
	padding-bottom: 180px;
}
@media (min-width: 600px){
	.section-dimension{
		margin-top: -125px;
	}
}
@media (min-width: 992px){
	.section-dimension{
		margin-top: -150px;
		padding-bottom: 200px;
	}
}
@media (min-width: 992px){
	.section-dimension{
		margin-top: -197.855px;
		padding-bottom: 264px;
	}
}
#contact-us .section-dimension{
    padding-bottom: 160px;
}
/* about & contact us */
.padding-x{
	padding-left: 30px;
	padding-right: 30px;
}


/* ------ styling for rtl ----- */
html:lang(ar) body{
    font-family: 'Cairo';
}
/* navigation */
html:lang(ar) .Toolbar, 
html:lang(ar) .Sidedrawer {
    direction: rtl;
    right: 0;
    left: auto;
}
html:lang(ar) .Open {
    transform: translateX(0);
}

html:lang(ar) .Close {
    transform: translateX(100%);
}
html:lang(ar) .Sidedrawer .langBtn{
    margin-left: 0;
    margin-right: 18px;
}    
html:lang(ar) .sidedrawerLogo{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (min-width: 1200px){
    html:lang(ar) .langBtn{
        margin-right: 0;
        margin-left: 20px;
    }
}

/* hero banner */
html:lang(ar) .HeroBanner{
    direction: rtl;
    text-align: right;
}
html:lang(ar) .HeroBanner .TextContainer{
    right: 30%;
    top: 15%;
}
html:lang(ar) .HeroBanner .Content .ImgContainer img{
    transform: rotatey(180deg);
} 
html:lang(ar) .HeroBanner .Content .ImgContainer {
    top: 23%;
    left: 15%;
}
@media(min-width: 500px){
    html:lang(ar) .HeroBanner .TextContainer{
        top: 28%;
    }
    html:lang(ar) .HeroBanner .Content .ImgContainer{
        top: 30%;
        left: 20%;
    }
}
@media (min-width: 600px) {
    html:lang(ar) .HeroBanner .Content .ImgContainer {
        left: 25%;
    }
}
@media(min-width: 992px){  
    html:lang(ar) .HeroBanner .Content .ImgContainer{
        left: 35%;
    }
}
@media(min-width: 1200px){
    html:lang(ar) .HeroBanner .Content .ImgContainer{
        left: 42%;
    } 
}
/* title */
html:lang(ar) .Title h2{
    font-size: 16px;
}
@media (min-width: 768px){
    html:lang(ar) .Title h2{
        font-size: 24px;
    }
}
@media (min-width: 1200px){
    html:lang(ar) .Title h2{
        font-size: 28px;
    }
}
/* about */
html:lang(ar) #about-us .row{
    direction: rtl;
    text-align: right;
}
@media(min-width: 1200px){
    html:lang(ar) .about-text p{
        margin-right: 0;
    }

    html:lang(ar) .about-text p{
        margin-left: 80px;
    }
}
/* team */
html:lang(ar) #team .row{
    direction: rtl;
    text-align: right;
}
html:lang(ar) #team .row img[alt="twitter"]{
    margin-left: 0;
    margin-right: 0.5rem;
}
/* contact us */
html:lang(ar)  #contact-us .row{
    text-align: right;
    direction: rtl;
}
@media(min-width: 992px){
    html:lang(ar) form .input-margin{
        margin-right: 0;
        margin-left: 1rem;
    }
}

html:lang(ar) #contact-us li img[alt="phone"]{
    transform: rotateY(180deg);
}
html:lang(ar) #contact-us .Info span {
    margin-left: 0;
    margin-right: 14px;
}
@media (min-width: 768px){
    html:lang(ar) #contact-us .Info span {
        margin-left: 0;
        margin-right: 28px;
    }
}
@media (min-width: 992px){
    html:lang(ar) #contact-us  .Info span {
        margin-left: 0;
        margin-right: 50px;
    }
}
