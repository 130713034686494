.scrollTop {
    position: fixed; 
    width: 50px;
    bottom: 30px;
    right: 30px;
    align-items: center;
    justify-content: center;
    z-index: 199;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
}

@keyframes fadeIn {
    0% {
    opacity: 0;
    }
    100% {
    opacity: 0.5;
    }
}
@media (min-width: 567px){
    .scrollTop{
        width: 60px;
    }
}
