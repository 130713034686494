.langBtn{
    font-size: 15px;
    font-weight: bold;
    line-height: 18px;
    color: #fff;
    background-color: #4B3494;
    border: 1px solid #4B3494;
    border-radius: 24px;
    width: 80px;
    height: 40px;
    cursor: pointer;
    outline: none;
    margin-left: 18px;
    margin-top: 15px;
}
@media (min-width: 1200px){
    .langBtn{
        margin-right: 20px;
        margin-top: 0;
        margin-left: 0;
    }
}  
