.about-dimension{
    padding-top: 40px;
    padding-bottom: 180px;
}
/* media query */
@media (min-width: 992px){
    .about-dimension{
        padding-bottom: 200px;
    }
}
@media (min-width: 992px){
    .about-dimension{
        padding-bottom: 264px;
    }
}
