footer{
    background-color: #4B3494;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    color: #fff;
    padding:14px 0;
    border-top: 0.5px solid #fff;
}
@media(min-width: 992px){
    footer{
        font-size: 18px;
        line-height: 43px;
        padding:18px 0;
    }
}