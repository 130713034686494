.Toolbar{
  height: 90px;
  width: 100%;
  position: fixed; 
  top: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center ;
  padding: 0 30px;
  box-sizing: border-box;
  z-index: 90;
}
.Wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}
.Toolbar nav{
  height: 100%;
}  
.Logo{
  padding: 12px 0;
  height: 100%;
}
.Logo img{ 
  height: 100%;
}
/* media query*/
@media (min-width: 768px){
  .Logo{
    padding:8px 0;
  }
}
@media(max-width: 1200px){ 
  .DesktopOnly{
    display: none;
  }
  .Logo{
    padding: 8px;
  }
}
@media(min-width: 1300px){ 
  .Toolbar{
    padding: 0 140px;
  }
}

