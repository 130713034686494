.HeroBanner{
    position: relative;
    max-height: 845px;
    overflow: hidden;
}

/* Style the video: 100% width and height to cover the entire window */
.HeroBanner .Video {
    width: 100%;
    height: 100%;
}

.HeroBanner .Content {
    position: absolute;
    top: 0;
    color:#ffff;
    font-weight: bold;
    background-color: rgba(75, 52, 148, 0.46);
    width: 100%;
    height: 100%;
    display: flex;
}
.HeroBanner .TextContainer{
    position: absolute;
    left: 30%;
    top: 25%;
    font-size: 22px;
}
.HeroBanner .Content p{
    font-size: 18px;
}
.HeroBanner .Content .ImgContainer{
    position: absolute;
    top: 28%;
    left: 58%;
}
.HeroBanner .Content .ImgContainer img{
    width: 30%;
} 
/* media query */
@media (min-width: 500px){
    .HeroBanner .TextContainer{
        position: absolute;
        left: 30%;
        top: 30%;
        font-size: 36px;
    }
    
    .HeroBanner .Content p{
        font-size: 18px;
    }
    
    .HeroBanner .Content .ImgContainer{
        top: 32%;
        left: 58%;
    }
    .HeroBanner .Content .ImgContainer img{
        width: 50%;
    }
}

@media(min-width: 768px){
    .HeroBanner .TextContainer{
        font-size: 92px;
        top: 32%;
    }
    .HeroBanner .Content p{
        font-size: 53px;
    } 
    .HeroBanner .Content .ImgContainer{
        top: 32%;
        left: 62%;
    } 
    .HeroBanner .Content .ImgContainer img{
        width: 100%;
    }
}
@media(min-width: 992px){
    .HeroBanner .Content .ImgContainer{
        left: 56%;
    } 

}
@media(min-width: 1200px){
    .HeroBanner .Content .ImgContainer{
        left: 50%;
    } 
}
