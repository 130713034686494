#clients .slick-slider{
    margin: 0 80px;
    padding: 0 20px;
}
#clients .slick-slide{
    outline: 0;
}
#clients .card-wrapper{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#clients .card-wrapper:active,
#clients .card-wrapper:hover,
#clients .card-wrapper:focus{
    outline: none;
    border: none;
}
#clients .card{
    transform: scale(0.8);
    width: 250px;
    height: 250px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}
#clients .card-image{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: transparent;
    transition: 0.5s;
}
#clients .card-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
#clients .slick-arrow{
    display: none!important;
}
/* media query */
@media screen and (min-width: 400px){
    #clients .card{
        transform: scale(1);
    }
}
@media screen and (max-width: 500px){
    #clients .slick-slider{
        margin: 0 20px;
        padding: 0 20px;
    }
    #clients .slick-arrow{
        display: none !important;
    }
}
@media screen and (min-width: 768px){
    #clients .slick-slider{
        padding: 0 20px;
    }

    #clients .slick-slide{
        padding: 0 20px;
    }
}
@media screen and (min-width: 1300px){
    #clients .slick-slider{
        padding: 0 80px;
    }
    #clients .slick-slide{
        padding: 0 40px;
    }
}
@media screen and (min-width: 1700px){
    #clients .slick-slide{
        padding: 0 20px;
    }
}