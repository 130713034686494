.Title{
    position: relative;
    text-align: center;
    width: 175px;
    height: 175px;
}
.Title img{
    width: 100%;
}
.Title h2{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
}
.TitleRight{ /* shared style for title in products & clients sections */
    color: #4B3494;
    left: calc(50% - 87.5px); /* % - 1/2 width of title */
    top: -87.5px;
}
.aboutTitle{
    color: #fff;
    left: calc(50% - 87.5px);
    margin-top: -87.5px; /* = height of Title/ 2 */
}
.ServicesTitle,
.teamTitle{
    color: #fff;
    left: calc(50% - 87.5px);
    top: -87.5px; /* = height of Title/ 2 */
}
.teamTitle{
    color: #4B3494;
    margin-bottom: 60px;
}
.contactTitle{
    color: #fff;
    left: calc(50% - 87.5px);
    top: -87.5px;
}
/* media query */
@media(min-width: 768px){
    .Title{
        width: 250px;
        height: 250px;
    }
    .Title h2{
        font-size: 27px;
    } 
    .TitleRight{ 
        left: calc(95% - 250px);
        top:-125px;
    }
    .aboutTitle{
        left: 5%;
        margin-top:-125px;
    }
    .ServicesTitle, .teamTitle{
        left: 5%;
        top: -125px;
    }
    .teamTitle{
        margin-bottom: 0;
    }
    .contactTitle{
        left: calc(95% - 250px);
        top:-125px;
    }
}

@media(min-width: 1200px){
    .Title{
        width: 300px;
        height: 300px;
    }
    .Title h2{
        font-size: 32px;
    }
    .TitleRight{ 
        top:-150px;
        left: calc(95% - 300px);
    }
    .aboutTitle{
        left: 5%;
        margin-top:-150px;
    }
    .ServicesTitle, .teamTitle{
        left: 5%;
        top: -150px; 
    }
    .contactTitle{
        top:-150px;
        left: calc(95% - 300px);
    }
}
